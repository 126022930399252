@import 'src/styles/common';
@import "~include-media/dist/include-media";

.responsiveCarouselContainer {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    cursor: pointer;
    user-select: none;

    :global(.slick-dots) {
      bottom: rem(100);
      display: flex !important;
      justify-content: center;
      align-items: center;

      @include media("<lg") {
        bottom: rem(100);
      }

      @include media("<md") {
        bottom: rem(95);
      }

      & > li {
        opacity: 1;
        margin: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
      }
      & > li > button {
        width: 100%;
        height: 100%;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;

        &::before {
          content: '';
          display: block;
          background: url("../../../../public/images/slider-dot.svg") no-repeat;
          width: 12px;
          height: 12px;
          position: relative;
          font-size: 0;
          opacity: .5;
        }
      }

      :global(.slick-active) {
        opacity: 1 !important;

        button {
          &::before {
            width: 16px;
            height: 16px;
            opacity: 1;
          }
        }
      }
    }
}
.respSlideContainer {
  width: 100%;
  height: fit-content;
  display: flex !important;
  align-items: center;
  flex-direction: column;
  position: relative;

  .respTextContainer {
    font-size: rem(15);

    @include media("<lg") {
      margin-top: rem(100);
    }

    @include media("<=sm") {
      margin-top: rem(50);
    }

    &:first-of-type {
      @media (max-width: map_get($breakpoints, lg)) {
        padding: 0 0 rem(54);
        margin-top: 0;
      }
      @media (max-width: map_get($breakpoints, sm)) {
        padding: 0 0 rem(22);
        margin-top: 0;
      }
    }

    @media (max-width: map_get($breakpoints, sm)) {
      padding: rem(10) 0 rem(0);
    }
    & > div > div {
      max-width: 100% !important;
    }
    h3 {
      font-family: $bodyFont;
      font-weight: bold;
      color: $secondary-darkgrey2;
      margin: 0;
      @media (max-width: map_get($breakpoints, lg)) {
        font-size: rem(16);
        line-height: rem(50);
      }
      @media (max-width: map_get($breakpoints, sm)) {
        font-size: rem(14);
        line-height: rem(45);
      }
      @media (max-width: map_get($breakpoints, xs)) {
        font-size: rem(12);
        line-height: rem(40);
      }
    }
    h2 {
      font-size: rem(18);
      line-height: rem(25);
      color: $primary;
      font-weight: bold;
      font-family: $bodyFont;
      margin-bottom: rem(18);
    }
  }
}

.imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 84%;
}

.laptopImage {
  position: relative;
  height: auto;
  width: 100%;
  z-index: 1;
  object-fit: contain;
}

.svgImageWrapper {
  position: absolute;
  width: 120%;
  height: 120%;
  top: -10%;
  left: -10%;

  @include media("<=sm") {
    width: 120%;
    height: 120%;
    top: -10%;
    left: -10%;
  }
}
.backgroundImage {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  width: 100%;
  animation: float 5s infinite alternate ease-in-out;
}

.slideButton {
  margin-top: 0;
  font-size: rem(14) !important;
  font-family: $bodyFont;
  font-weight: 700 !important;
  padding: 0;
}

.buttonLink {
  text-decoration: none;
}

@keyframes float {
  from {
    transform: translate(-50%, -50%) scale(1.1);
  }
  to {
    transform: translate(-50%, -54%) scale(1.1);
  }
}
