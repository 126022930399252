@import "~include-media/dist/include-media";
@import '../../../styles/common';

.featuredCarouselContainer {
  max-width: rem(legacy-rem(1760));
  --content-background-color: none !important;
  height: fit-content;
  position: relative;
  margin: 0 0 0 auto;

  @media (max-width: map_get($breakpoints, md)) {
    display: none;
  }

  @media (min-width: map_get($breakpoints, xxxl)) {
    margin: 0;
    max-width: rem(legacy-rem(1795));
  }
}

.buttonContainer {
  width: 100%;
  max-width: rem(legacy-rem(110));
  display: flex;
  margin-top: rem(legacy-rem(45));

  .sliderButton {
    width: rem(legacy-rem(44.928));
    height: rem(legacy-rem(44.928));
    border: none;
    outline: none;
    background: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &:first-of-type{
      margin-right: rem(26px);
    }

    img {
      width: 100%;
    }
    &__next {
      @extend .sliderButton;
    }
  }
}

.slideContainer {
  width: 100%;
  padding: 0;
  height: fit-content;
  display: flex;
  justify-content:flex-start;

  .textContainer {
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;

    @media (min-width: map_get($breakpoints, lg)) {
      margin-top: rem(120);
      justify-content: flex-start;
    }

    @media (min-width: map_get($breakpoints, xl)) {
      margin-top: rem(140);
      width: rem(450);
      height: rem(legacy-rem(800));
    }

    @media (min-width: map_get($breakpoints, xxl)) {
      margin-top: rem(170);
    }

    :global(.caseStudyButton) {
      font-weight: bold;
    }

    & > div > div {
      max-width: 100%;
    }

    h3 {
      font-size: rem(legacy-rem(20));
      line-height: rem(legacy-rem(26));
      margin: 0 0 rem(legacy-rem(20));
      padding: 0;
      font-weight: 600;
      font-family: $bodyFont;
      color: $secondary-darkgrey2;
    }

    h2 {
      font-family: $bodyFont;
      font-size: rem(legacy-rem(24));
      line-height: rem(legacy-rem(32));
      color: $primary;
      font-weight: bold;
      margin-bottom: rem(legacy-rem(30));

      @media (min-width: map_get($breakpoints, xl)) {
        font-size: rem(legacy-rem(26));
        margin-bottom: rem(legacy-rem(40));
      }

    }

    ul {
      display: inline-block;
      padding: 0;
      margin: 0 0 rem(legacy-rem(30));

      @media (min-width: map_get($breakpoints, xl)) {
        margin: 0 0 rem(legacy-rem(58));
      }

      & > div {
        margin-bottom: rem(legacy-rem(10));

        @media (min-width: map_get($breakpoints, xl)) {
          margin-bottom: rem(legacy-rem(20));
        }
      }

      li {
        padding: 0;
        align-items: center;

        span {
          width: 100%;
          font-size: rem(legacy-rem(16));
          font-family: $bodyFont;
          color: $secondary-darkgrey2;
          font-weight: 400;

          @media (min-width: map_get($breakpoints, xl)) {
            font-size: rem(legacy-rem(20));
            line-height: rem(legacy-rem(32));
          }
        }
      }
    }

    .buttons {
      position: relative;
    }

    .buttonLink {
      text-decoration: none;
    }
  }
}

.imageContainer {
  width: 70%;
  height: rem(legacy-rem(600));
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: map_get($breakpoints, xl)) {
    width: rem(1500);
    height: rem(legacy-rem(900));
  }

  @media (min-width: map_get($breakpoints, xxl)) {
    height: rem(legacy-rem(1000));
  }
}

.laptopImage {
  position: relative;
  height: auto;
  width: 100%;
  z-index: 1;
  object-fit: contain;
}

.backgroundImage {
  position: absolute;
  z-index: 0;
  top: 50%;
  left: 50%;
  width: 100%;
  transform:scale(0);
  animation: float 5s infinite alternate ease-in-out;
}

.leftArrow,
.rightArrow {
  transition: all 0.2s ease-in-out;
  &:hover {
    fill: $brand-pink;
    transform: scale(1.075);
  }
}

@keyframes float {
  from {
    transform: translate(-50%, -50%) scale(1.12);
  }
  to {
    transform: translate(-50%, -54%) scale(1.12);
  }
}

.sliderContainer {
  :global(.carousel__slide--hidden) {
    visibility: hidden;
  }
}

.listItem {
  margin-left: rem(14);

  &::marker {
    color: #4a4a4a
  }
}
