@import "~include-media/dist/include-media";
@import '../../styles/common';

.container {
  display: flex;
  justify-content: flex-start;
}

.featuredProjectsWrapper {
  margin-top: rem(50);
  width: 100%;
  position: relative;
  z-index: 2;
  overflow: hidden;
  padding-bottom: rem(30);

  @include media("<=md") {
    padding-bottom: rem(50);
  }

  @media (min-width: map_get($breakpoints, lg)) {
    padding-left: rem(90);
    margin-top: rem(legacy-rem(100));
    overflow-x: hidden;
  }

  @media (min-width: map_get($breakpoints, xl)) {
    padding-left: rem(330);
    padding-bottom: rem(legacy-rem(50));
  }

  @media (max-width: map_get($breakpoints, md)) {
    padding-top: rem(60);
  }
}
